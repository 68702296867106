import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
    SpamSearchResult,
    useGetLabelledSpamLazyQuery,
} from '../../../api/client'
import { toastError } from '../../../components/Notification'
import PlatformDropdown from '../../../components/PlatformDropdown'
import SimpleLoader from '../../../components/SimpleLoader'
import ErrorCallout from '../../../components/errors/ErrorCallout'
import {
    PROFILE_PARAM_NAME,
    SocialMediaServiceFilterOptions,
    dropDownToGraphQLEnum,
} from '../../../models/SocialMediaServiceSearch'
import { SpamContentList } from './SpamContentList'

/**
 * This page lets users search for and remove spam that was incorrectly added to the spam model.
 *
 * It performs a "range" search against the spam embedding.
 *
 * Tips on development:
 * When developing locally the backend spamService won't be able to access the DNS for the
 * data services API. To resolve this you can do two things:
 * - use the stub `export STUB_CLASSIFICATION_SERVICE="true"` in your local env to use the stub service which returns two contentIds
 * - use `lk ui start` to use the remote tenmoku API (this assumes it is up to date)
 */
function SpamModelPage() {
    const [searchParams] = useSearchParams()
    const socialMediaServiceParam = searchParams.get(
        PROFILE_PARAM_NAME
    ) as keyof typeof SocialMediaServiceFilterOptions
    let socialMediaService = dropDownToGraphQLEnum(socialMediaServiceParam)

    const [spamIds, setSpamIds] = useState<number[]>()
    const [scores, setScores] = useState<SpamSearchResult[] | null>(null)
    const [searchText, setSearchText] = useState('')
    const [threshold, setThreshold] = useState(80)

    const [fetchLabelledSpam, { loading, error }] = useGetLabelledSpamLazyQuery(
        {
            onCompleted: (data) => {
                setSpamIds(data.getLabelledSpam!?.map((i) => i?.contentId!))
                setScores(data.getLabelledSpam as SpamSearchResult[])
            },
            onError: (error) => {
                toastError(error.message)
            },
        }
    )

    async function handleSearch(event: React.MouseEvent) {
        // This stops the page from reloading
        event.preventDefault()
        await fetchLabelledSpam({
            variables: {
                message: searchText,
                platform: socialMediaService,
                threshold: threshold / 100,
            },
        })
    }

    return (
        <>
            <div className="mx-10 mt-10 prose prose-stone prose-lg ">
                <h2 className="text-gray-950 initial">
                    Manage the content of the spam model
                </h2>
                <p>
                    This page lets you search for and remove content from the
                    spam model. You can also use this page to experiment to find
                    content that might be identified as spam.
                </p>
                <p>
                    <strong>NOTE:</strong> items will take 15-30 minutes to be
                    removed from the spam model.
                </p>
            </div>
            <div className="mx-10 mb-10">
                <div className="flex flex-col ">
                    <div className="min-w-0 flex-1  mr-6 mt-3 mb-3">
                        <label htmlFor="comment">
                            Start by entering the phrase you want to search
                            for...
                        </label>
                        <textarea
                            rows={3}
                            id="comment"
                            className="block w-full bg-white hover:bg-gray-100 border border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary rounded-md"
                            placeholder=""
                            defaultValue={''}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                    <div className="mr-6 mt-3 mb-3">
                        Only show items with a similarity score greater than...
                        <input
                            type="text"
                            className="block bg-white hover:bg-gray-100 border border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary rounded-md"
                            id="threshold"
                            defaultValue={80}
                            onChange={(e) =>
                                setThreshold(parseFloat(e.target.value))
                            }
                        ></input>
                    </div>
                    <div className="mr-6 mt-3 mb-3">
                        <label>
                            Specify which platforms you are interested in...
                        </label>
                        <PlatformDropdown
                            onChange={() => {
                                // No action needed here.
                            }}
                        />
                    </div>

                    <div className="mr-6 mt-3 mb-3">
                        <button
                            onClick={handleSearch}
                            className="inline-flex items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                        >
                            Search
                        </button>
                    </div>
                </div>
            </div>
            <div className="mx-10 mb-10">
                <SimpleLoader loading={loading} />
                {error ? (
                    <ErrorCallout message="There was an error fetching the spam content" />
                ) : null}
                {spamIds && spamIds.length > 0 && scores && !loading && (
                    <SpamContentList spamIds={spamIds} scores={scores} />
                )}
            </div>
        </>
    )
}

export default SpamModelPage
